import { useEffect } from "react";
import { connect } from "react-redux";
import Home from "../../pages/Home";
import Header from "../Header";
import {
  changeStep,
  changeBackgroundImage,
  setInitialFormData,
} from "../../services/Actions/Auth";
import "./main.scss";

function Main(props) {
  useEffect(() => {
    props.changeBackgroundImage("insurance-img1.jpg");
    props.changeStep(1);
    props.setInitialFormData({});
  }, []);
  return (
    <div className="Main">
      <Header />
      <Home />
    </div>
  );
}

const mapDispatchToProps = {
  changeStep,
  changeBackgroundImage,
  setInitialFormData,
};

export default connect(null, mapDispatchToProps)(Main);
