export const CHANGE_APP_BACKGROUND_IMAGE = "CHANGE_APP_BACKGROUND_IMAGE";
export const CHANGE_STEP = "CHANGE_STEP";
export const SAVE_STEP_1 = "SAVE_STEP_1";
export const SAVE_STEP_2 = "SAVE_STEP_2";
export const SAVE_STEP_3 = "SAVE_STEP_3";
export const SAVE_STEP_4 = "SAVE_STEP_4";
export const SAVE_STEP_5 = "SAVE_STEP_5";
export const SAVE_STEP_6 = "SAVE_STEP_6";
export const SAVE_STEP_8 = "SAVE_STEP_8";
export const SAVE_STEP_9 = "SAVE_STEP_9";
export const SAVE_STEP_11 = "SAVE_STEP_11";
export const SAVE_STEP_12 = "SAVE_STEP_12";
export const SAVE_FINAL_STEP = "SAVE_FINAL_STEP";
export const SET_INITIAL_FORM_DATA = "SET_INITIAL_FORM_DATA";
