import { useState } from "react";
import { connect } from "react-redux";
import postal from "postal-code";
import { saveStep11, changeStep } from "../../../services/Actions/Auth";
function Step11(props) {
  let { region, address } = props;
  const [postalCode, setPostalCode] = useState("");
  const [formData, setFormData] = useState({
    address: address,
    region: region,
  });
  function handleClick() {
    if (formData.region && formData.address) {
      props.changeStep(12);
    } else {
      alert("Please Fill out All the fields.");
    }
  }

  function handleChange(e) {
    formData[e.target.name] = e.target.value;
    props.saveStep11(formData);
  }
  function handlePostalCode(e) {
    setPostalCode(e.target.value)
    if(e.target.value) {
      let region = postal.getRegions(e.target.value);
      formData["region"] = region[0];
      props.saveStep11(formData);
    } else {
      formData["region"] = "";
      props.saveStep11(formData);
    }
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>In which region do you live?</h2>
        </div>
        <div className="content-box">
          <div className="text" data-aos="fade-in" data-aos-delay="300">
            <p>
              WHY? Some tariffs are only offered regionally. <br />
              To compare all tariffs for you, we need your place of residence.
            </p>
          </div>
        </div>
        <div className="form-wrapper postal">
          <div className="form-group">
            <div className="input-wrapper" data-aos="fade-up" data-aos-delay="500">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                type="text"
                id="postalCode"
                placeholder="Postal Code"
                name="postalCode"
                onChange={handlePostalCode}
                value={postalCode}
                className="form-control"
              />
            </div>
            <div className="input-wrapper" data-aos="fade-up" data-aos-delay="500">
              <label htmlFor="region">Location</label>
              <input
                type="text"
                id="region"
                placeholder="Location"
                name="region"
                onChange={handleChange}
                value={formData.region}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="input-wrapper" data-aos="fade-up" data-aos-delay="700">
            <label htmlFor="address">Address</label>
            <textarea
              type="text"
              id="address"
              placeholder="Street / House Number"
              name="address"
              onChange={handleChange}
              value={formData.address}
              className="form-control"
            />
          </div>
        </div>

        <button
          className="button"
          onClick={handleClick}
          data-aos="fade-up"
          data-aos-delay="900"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  let { region, address } = state.Auth.formData;
  return {
    region,
    address,
  };
};

const mapDispatchToProps = {
  saveStep11,
  changeStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step11);
