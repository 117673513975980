import { connect } from "react-redux";
import { changeStep } from "../../../services/Actions/Auth";
function Step13(props) {
  function handleClick() {
    props.changeStep(14);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>Done!</h2>
        </div>

        <div className="content-box">
          <div className="text">
            <h2 data-aos="fade-in" data-aos-delay="300">
              We have found some <span>very interesting tariffs</span> for you.
            </h2>
            <p data-aos="fade-in" data-aos-delay="500">
              On the next page you can request your free comparison.
            </p>
          </div>

          <button
            className="button"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step13);
