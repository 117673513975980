function ThankYou(props) {
  return (
    <div className="Step thankyou">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>Thank you!</h2>
        </div>

        <div className="content-box">
          <div className="text" data-aos="fade-up" data-aos-delay="300">
            <p>
              We have received your data and first check your information for
              authenticity. <br />
              If the check is successful, you will receive your rate comparison
              from one of our experts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
