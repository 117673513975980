import { useEffect } from "react";
import { Switch, Route, Router, Link } from "react-router-dom";
import acceptCookies from "./services/cookies/accept-cookies";
import { connect } from "react-redux";
import "./App.scss";
import Main from "./layouts/Main";
import Survey from "./layouts/Survey";
import Disclosure from "./pages/Privacy/Disclosure";
import DataSecurity from "./pages/Privacy/DataSecurity";
import AOS from "aos";

function App(props) {
  useEffect(() => {
    AOS.init();
  }, []);
  let { appBackgroundImage } = props;
  return (
    <div className="App">
      <div className="background-image">
        <img src={require(`${appBackgroundImage}`)} alt="Background" />
      </div>
      <div className="background-overlay"></div>

      <Switch>
        <Route path="/" exact>
          <Main />
        </Route>
        <Route path="/survey">
          <Survey />
        </Route>
        <Route path="/impressum">
          <Disclosure />
        </Route>
        <Route path="/datenschutz">
          <DataSecurity />
        </Route>
      </Switch>

      <Link to="/impressum" className="bottom-page-link">Impressum</Link>
    </div>
  );
}

const mapStateToProps = (state) => {
  let { appBackgroundImage } = state.Auth;
  return {
    appBackgroundImage,
  };
};

export default connect(mapStateToProps, null)(App);
