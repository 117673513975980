import { connect } from "react-redux";
import { saveStep1, changeStep } from "../../../services/Actions/Auth";
function Step1(props) {
  function handleClick(e) {
    props.saveStep1(e.target.value);
    props.changeStep(2);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>How are you currently insured?</h2>
        </div>
        <div className="option-box">
          <button
            className="button outline"
            value="Statutory health insurance"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Statutory health insurance
          </button>
          <button
            className="button outline"
            value="Private health insurance"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Private health insurance
          </button>
          <button
            className="button outline"
            value="I’m currently not insured"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            I’m currently not insured
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveStep1,
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step1);
