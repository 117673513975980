import "./styles.scss";
export default function DataSecurity() {
  return (
    <div className="content-page">
      <a href="https://pkv-vergleichen.digital/">
        <b>
          <span data-contrast="none">PKV-VERGLEICHEN.DIGITAL</span>
        </b>
      </a>
      <span data-ccp-props='{"335551550":2,"335551620":2}'> </span>

      <b>
        <span data-contrast="none">Datenschutzerklärung</span>
      </b>
      <span data-ccp-props='{"201341983":2,"335559738":180,"335559739":105,"335559740":338}'>
         
      </span>

      <span data-contrast="none">
        Der Datenschutz ist uns ein besonderes Anliegen. Unsere Aktivitäten zur
        Erfüllung der Anforderungen der Datenschutz-Grundverordnung (DS-GVO),
        des Bundesdatenschutzgesetzes (BDSG), des Telemediengesetzes (TMG) und
        des Sozialgesetzbuchs X (SGB X) sind an dem Ziel ausgerichtet, unserem
        Respekt vor Ihrer Privat- und Persönlichkeitssphäre Ausdruck zu
        verleihen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Verantwortliche Stelle für die Datenverarbeitungen und
              Kontaktmöglichkeiten des Datenschutzbeauftragten
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">Verantwortliche Stelle ist die</span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">MY-GERMAN-INSURANCE.DE</span>
      <span data-contrast="none">eine Marke der AP Consulting GmbH</span>
      <span data-contrast="none">Berlepschstraße 35</span>
      <span data-contrast="none">36124 Eichenzell</span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">E-Mail: </span>
      <a href="mailto:kontakt@my-german-insurance.de">
        <b>
          <span data-contrast="none">kontakt@my-german-insurance.de</span>
        </b>
      </a>
      <span data-contrast="none"> </span>
      <span data-contrast="none">Telefon: </span>
      <a href="tel:066596159963">
        <b>
          <span data-contrast="none">(06659) 615 99 63</span>
        </b>
      </a>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">Datenschutzbeauftragter ist: Ali Adibi.</span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">Sie können ihn erreichen unter:</span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <a href="mailto:datenschutz@my-german-insurance.de">
        <b>
          <span data-contrast="none">datenschutz@</span>
        </b>
        <span data-contrast="none">my-german-insurance.de</span>
      </a>
      <span data-contrast="none"> </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="2">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Was personenbezogene Daten sind und woher wir sie erhalten
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren
        besonderen Merkmalen identifiziert werden kann, die Ausdruck der
        physischen, physiologischen, genetischen, psychischen, wirtschaftlichen,
        kulturellen oder sozialen Identität dieser natürlichen Person sind.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              a) Daten, die beim Besuch der Internetseite anfallen
            </span>
          </b>
          <span data-contrast="none">
            Sie können unsere Internetseiten grundsätzlich ohne die Angabe
            personenbezogener Daten einsehen. Wir speichern hierbei
            standardmäßig ausschließlich die Website, von der aus Sie zu unserem
            Angebot gelangt sind, den Namen Ihres Internet-Service-Providers,
            welche Websites Sie innerhalb unseres Internet-Angebots besucht
            haben sowie Datum und Dauer Ihres Besuches. Hierfür werden für die
            Dauer Ihres Besuches im Arbeitsspeicher Ihres Computers kleine
            Dateien (so genannte Cookies) abgelegt. Es handelt sich dabei um so
            genannte temporäre Session-Cookies (zu permanenten Cookies siehe
            unten unter Ziffer 9). Die entsprechenden Daten werden dabei auf
            Servern unseres DienstleistersStrato AG gespeichert. Die
            Session-Cookies werden automatisch gelöscht, sobald Sie Ihr
            Browser-Fenster schließen. Wir erstellen mithilfe der
            Session-Cookies eine so genannte Session-ID für interne statistische
            Zwecke. Die gewonnenen Daten sind vollständig anonymisiert,
            enthalten also keinerlei Möglichkeit Sie als Person zu
            identifizieren.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Ihre IP-Adresse sowie ein Time-Stamp werden aus Sicherheitsgründen
        gespeichert und lediglich für interne Zwecke genutzt. Die IP-Adresse ist
        dabei eine maschinenbezogene Kennung, die eine Aussage über den
        verwendeten Rechner für den Internet-Zugang oder benutzten
        Internet-Gateway macht, und zwar zum Zeitpunkt der Online-Abfrage. Der
        Begriff Time-Stamp bezeichnet einen Wert in einem definierten Format,
        der einem Ereignis (beispielsweise dem Senden oder Empfangen einer
        Nachricht, der Modifikation von Daten u. a.) einen Zeitpunkt zuordnet.
        Der Zweck eines Zeitstempels ist es, für Menschen oder Computer deutlich
        zu machen, wann welche Ereignisse eingetreten sind.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              b) Personenbezogene Daten, die Sie uns über ein Formular mitteilen
            </span>
          </b>
          <span data-contrast="none">
            Wir erhalten Ihre personenbezogenen Daten, wenn Sie diese Daten in
            die Formulare auf unserer Seite eingeben und uns so zur Verfügung
            stellen. Welche Daten dies genau sind hängt davon ab, für welche
            Dienstleistung Sie sich genau interessieren.
          </span>
        </li>
      </ol>
      <span data-contrast="none">
        Für Angebote, Vergleiche oder Beratungen in den Bereichen private
        Krankenversicherung, Krankenzusatzversicherung,
        Berufsunfähigkeitsversicherung, Kapitalanlage, Immobilie als
        Kapitalanlage, erheben wir folgende Daten:
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ul>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="1"
          data-aria-level="1"
        >
          <span data-contrast="none">Ihr Geschlecht,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="2"
          data-aria-level="1"
        >
          <span data-contrast="none">
            Ihren vollständigen und richtigen Namen,
          </span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="3"
          data-aria-level="1"
        >
          <span data-contrast="none">Ihre E-Mail-Adresse,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="4"
          data-aria-level="1"
        >
          <span data-contrast="none">
            Ihre vollständige und richtige postalische Anschrift,
          </span>
          <span data-ccp-props="{}"> </span>
        </li>
      </ul>
      <ul>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="1"
          data-aria-level="1"
        >
          <span data-contrast="none">Ihre Telefonnummer,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="2"
          data-aria-level="1"
        >
          <span data-contrast="none">Ihr Geburtsdatum,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="3"
          data-aria-level="1"
        >
          <span data-contrast="none">Ihren Beruf,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="4"
          data-aria-level="1"
        >
          <span data-contrast="none">Ihre Staatsangehörigkeit,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="5"
          data-aria-level="1"
        >
          <span data-contrast="none">Ihre Sozialversicherungsnummer,</span>
          <span data-ccp-props="{}"> </span>
        </li>
      </ul>
      <ul>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="1"
          aria-setsize="-1"
          data-aria-posinset="1"
          data-aria-level="1"
        >
          <span data-contrast="none">
            Gegebenenfalls weitere produktbezogene Daten
          </span>
          <span data-ccp-props="{}"> </span>
        </li>
      </ul>
      <span data-contrast="none"> </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="3">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Zu welchen Zwecken wir Ihre personenbezogenen Daten verwenden
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Personenbezogene Daten dürfen von uns nur mit Ihrer oder einer
        gesetzlichen Erlaubnis aus der DS-GVO, dem BDSG oder einem anderen den
        Datenschutz regelnden Gesetzen verarbeitet werden.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              a) Verarbeitung zur Erfüllung von vorvertraglichen und
              vertraglichen Pflichten (Art. 6 Abs. 1 b) DS-GVO)
            </span>
          </b>
          <span data-contrast="none">
            Die Erhebung Ihrer personenbezogenen Daten dient uns in erster Linie
            dazu, die Dienst- und Beratungsleistungen, die Sie wünschen,
            anzubieten bzw. durch unsere Kooperationspartner anbieten zu lassen.
            Hierzu schließen Sie mit uns einen Vermittlervertrag. Damit wir
            diesen Vertrag Ihnen gegenüber erfüllen können, müssen wir Ihre
            Daten verarbeiten.
          </span>
        </li>
        <li>
          <b>
            <span data-contrast="none">
              b) Verarbeitung zur Wahrung von berechtigten Interessen (Art. 6
              Abs. 1 f) DS-GVO)
            </span>
          </b>
          <span data-contrast="none">
            In gewissen Fällen verarbeiten wir Ihre Daten, damit wir unsere oder
            die Interessen eines Dritten wahren können. Hierbei handelt es sich
            beispielsweise um die Auskunftseinholung und den Datenaustauch mit
            einer Auskunftei, um Aussagen über Ihre Bonität zu erhalten. Dies
            ist die Verband der Vereine Creditreform e.V.,
          </span>
          <span data-contrast="none"> </span>
          <span data-contrast="none">
            Hammfelddamm13, D-41460 Neuss, Tel: +49 2131 109-0, Fax: +49 2131
            109-8000  ( zu den dort durchgeführten Datenverarbeitungen für die
            Adressvalidierung und die Bonitätsprüfung auch unten 4 b). Außerdem
            verarbeiten wir gewisse Daten die bei der Nutzung unserer
            Internetseite anfallen, um Ihnen zu ermöglichen sie zu benutzen,
            oder die Sicherheit unserer IT-Systeme zu gewährleisten.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Schließlich senden wir Ihnen, wenn Sie sich für einen unserer Services
        registriert haben, in regelmäßigen Abständen Neuigkeiten über uns und
        unsere Produkte per E-Mail zu. Wir verarbeiten hierzu Ihre
        E-Mail-Adresse, um Ihnen die Nachrichten zuzusenden sowie ggf. weitere
        Daten, die Sie uns im oben genannten Wege über ein Formular mitgeteilt
        haben, um diese Nachrichten auf Sie zu personalisieren und, um sie Ihren
        Interessen gemäß besser ansprechen zu können. Sie können dem Erhalt
        dieser Nachrichten jederzeit widersprechen, indem Sie entweder den
        Abmeldelink in der Nachricht betätigen oder uns eine Nachricht an die
        o.g. Adressen schreiben.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="10">
        <li>
          <b>
            <span data-contrast="none">
              c) Verarbeitung aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 a)
              DS-GVO
            </span>
          </b>
          <span data-contrast="none">
            Wir verarbeiten Ihre Daten zur Versendung eines E-Mail-Newsletters
            aufgrund einer Einwilligung, wenn Sie nicht Kunde bei uns sind, sich
            aber dennoch für unseren E-Mail-Newsletter angemeldet haben. Zudem
            setzen wir bestimmte Cookies nur mit Ihrer Einwilligung. Weitere
            Hinweise hierzu, finden Sie unter Ziffer 10.
          </span>
        </li>
        <li>
          <b>
            <span data-contrast="none">
              d) Aufgrund gesetzlicher Vorgaben (Art. 6 Abs. 1 c) DS-GVO
            </span>
          </b>
          <span data-contrast="none">
            Schließlich verarbeiten wir Ihre Daten, um steuerliche und
            handelsrechtliche Buchführungs- und Aufzeichnungspflichten zu
            erfüllen.
          </span>
        </li>
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Wer Ihre Daten empfängt und wann eine Übermittlung in Drittländer
              erfolgt
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        In den folgenden Unterpunkten erklären wir, wem wir Ihre Daten mitteilen
        und wann wir eine Übermittlung in sog. Drittländer vornehmen.
        Drittländer sind solche Länder, die außerhalb des europäischen
        Wirtschaftsraums liegen. Intern werden Ihre Daten bei uns von mehreren
        Abteilungen verarbeitet. An externe Dienstleister übermitteln wir Daten
        vor allem, da wir manche Leistungen nicht oder nicht sinnvoll selbst
        erbringen können. Wir haben externe Dienstleister, die wir für alle
        unsere Datenverarbeitungen einsetzen und externe Dienstleister, an die
        nur dann Daten gegeben werden, wenn Sie sich für eine bestimmte
        Vermittlung entscheiden.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              a) Interne Empfänger und allgemeine externe Dienstleister
            </span>
          </b>
          <span data-contrast="none">
            Intern haben alle Abteilungen Zugriff auf Ihre Daten, welche die
            Daten benötigen, um die oben genanntenZwecke zu erfüllen. Zudem
            setzen wir externe Dienstleister ein, um die Daten zu diesen Zwecken
            zu verarbeiten. Diese externen Dienstleister sind vor allem Anbieter
            von IT-Dienstleistungen und Telekommunikation sowie Telefonnummern-
            und Adressvalidierungsdienste. Außerdem setzen wir zum technischen
            Versand unseres E-Mail-Newsletters externe Dienstleister ein.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Für unsere individuelle E-Mail-Korrespondenz nach dem ersten Versand
        verwenden wir den Dienst „Mail“ des Anbieters Google, der
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
        Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA. Teile der Datenverarbeitung finden in den USA statt. Wir
        haben hierfür mit der Google LLC Standarddatenschutzklauseln
        abgeschlossen. Eine Kopie finden Sie hier: 
      </span>
      <a href="https://gsuite.google.com/terms/mcc_terms.html">
        <b>
          <span data-contrast="none">
            https://gsuite.google.com/terms/mcc_terms.html
          </span>
        </b>
      </a>
      <span data-contrast="none">
        . Sofern Sie diese Datenverarbeitung nicht wünschen, räumen wir Ihnen
        die Möglichkeit ein, dieser per Mail an die vorgenannte Adresse zu
        widersprechen. Allerdings werden wir dann mit Ihnen nicht mehr per
        E-Mail korrespondieren können. Auf diese Folge weisen wir Sie gesondert
        per Mail hin, bevor wir Ihren Widerruf umsetzen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              b) Angebot, Vergleich oder Beratung in den Bereichen private
              Krankenversicherung, Krankenzusatzversicherung,
              Berufsunfähigkeitsversicherung, Kapitalanlage, Immobilie als
              Kapitalanlage
            </span>
          </b>
          <span data-contrast="none">
            Damit wir die von Ihnen gewünschte Vermittlungsleistung erbringen
            können, gibt AP Consulting GmbH Ihre Daten entweder unmittelbar an
            einen geeigneten externen Experten oder Expertenpool, welcher nach
            Ansicht von AP Consulting GmbH für das jeweils angefragte Produkt
            oder die jeweils angefragte Information besonders geeignet ist, oder
            an ein Kooperationsunternehmen weiter, das den geeigneten Experten
            im Auftrag von AP Consulting GmbH auswählt. An welchen Experten eine
            Weitergabe der Daten erfolgt, lässt sich vor oder bei Ihrer Anfrage
            noch nicht voraussagen. Allerdings steht fest, dass eine Weitergabe
            nur an Personen erfolgt, die eine einschlägige Spezialisierung
            aufweisen und in der Regel in Ihrem Postleitzahlenbereich tätig
            sind.
          </span>
        </li>
      </ol>
      <span data-contrast="none">
         Der oder die Experten werden mit Ihnen Kontakt aufnehmen und Ihnen dann
        Ihre individuelle Information zukommen lassen. Sollten Sie aufgrund der
        Tätigkeit eines Experten Interesse am Vertragsschluss bei einem
        konkreten Anbieter haben, übermittelt der Experte die Daten an die
        entsprechende Stelle bzw. einen Maklerpool (zur Erklärung: ein
        Maklerpool unterstützt den Versicherungsexperten in der
        Geschäftsabwicklung mit den Versicherungsgesellschaften).
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Bei Anfragen zur privaten Krankenversicherung übermitteln wir Ihre Daten
        (insbesondere Name, Geburtsdatum und Anschrift) zum Zwecke der
        Bonitätsprüfung an die Verband der Vereine Creditreform
        e.V., Hammfelddamm 13, D-41460 Neuss, Tel: +49 2131 109-0, Fax: +49 2131
        109-8000. Detaillierte Informationen zu der Datenverarbeitung bei der
        infoscore Consumer Date GmbH finden Sie unter: 
      </span>
      <span data-contrast="none">
        https://www.boniversum.de/eu-dsgvo/informationen-nach-eu-dsgvo-fuer-verbraucher/.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="5">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Wie lange Ihre Daten gespeichert werden
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Wie lange Ihre Daten gespeichert werden, richtet sich nach der
        Rechtsgrundlage der Datenverarbeitung.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Daten, die auf vertraglicher Grundlage gespeichert werden und einen
        einmaligen Leistungsaustausch zum Gegenstand haben – wie unsere
        Vermittlungsleistungen – werden aufgrund der gesetzlichen
        Verjährungsfristen für drei Jahre nach dem Schluss des Jahres aufbewahrt
        in dem der Vertrag abgeschlossen wurde.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Haben Sie unseren E-Mail-Newsletter abonniert, sei es aufgrund Ihrer
        Einwilligung, oder weil Sie Kunde bei uns sind, speichern wir die für
        die Versendung erforderlichen Daten bis zu Ihrer Abmeldung.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Daten, die wir aufgrund eines berechtigten Interesses verarbeiten,
        speichern wir für drei Jahre ab der Erhebung der Daten.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Es kann erforderlich sein, dass wir aufgrund unserer gesetzlichen
        Pflichten gewisse Daten für einen längeren Zeitraum von sechs bis zehn
        Jahren vorhalten müssen. In diesem Zeitraum findet keine Nutzung der
        Daten zu anderen Zwecken als der Archivierung statt.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Nach Ablauf der letzten der o.g. Speicherfristen, bleiben die Daten für
        bis zu drei weitere Monate gespeichert, damit wir eine technisch
        gründliche und planmäßige Löschung umsetzen können. Innerhalb dieser
        drei Monate werden die Daten nicht mehr zu anderen Zwecken verarbeitet.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="6">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Ihre Betroffenenrechte und Ihr Widerruf einer Einwilligung
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Die Datenschutz-Grundverordnung garantiert Ihnen gewisse Rechte, die Sie
        uns gegenüber geltend machen können. Sie haben das Recht:
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ul>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="2"
          aria-setsize="-1"
          data-aria-posinset="1"
          data-aria-level="1"
        >
          <span data-contrast="none">
            von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende
            personenbezogene Daten verarbeitet werden und wenn ja, die näheren
            Umstände der Datenverarbeitung 
          </span>
          <b>
            <span data-contrast="none">
              (Art. 15 DS-GVO: Auskunftsrecht der betroffenen Person)
            </span>
          </b>
          <span data-contrast="none">,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="2"
          aria-setsize="-1"
          data-aria-posinset="2"
          data-aria-level="1"
        >
          <span data-contrast="none">
            von uns unverzüglich die Berichtigung Sie betreffender unrichtiger
            personenbezogener Daten zu verlangen. Dabei haben Sie unter
            Berücksichtigung der Zwecke der Verarbeitung auch das Recht, die
            Vervollständigung unvollständiger personenbezogener Daten – auch
            mittels einer ergänzenden Erklärung – zu verlangen 
          </span>
          <b>
            <span data-contrast="none">
              (Art. 16 DS-GVO: Recht auf Berichtigung)
            </span>
          </b>
          <span data-contrast="none">,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="2"
          aria-setsize="-1"
          data-aria-posinset="3"
          data-aria-level="1"
        >
          <span data-contrast="none">
            von uns zu verlangen, dass Sie betreffende personenbezogene Daten
            unverzüglich gelöscht werden 
          </span>
          <b>
            <span data-contrast="none">
              (Art. 17 DS-GVO: Recht auf Löschung)
            </span>
          </b>
          <span data-contrast="none">,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="2"
          aria-setsize="-1"
          data-aria-posinset="4"
          data-aria-level="1"
        >
          <span data-contrast="none">
            von uns die Einschränkung der Verarbeitung zu verlangen 
          </span>
          <b>
            <span data-contrast="none">
              (Art. 18 DS-GVO: Recht auf Einschränkung der Verarbeitung)
            </span>
          </b>
          <span data-contrast="none">,</span>
          <span data-ccp-props="{}"> </span>
        </li>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="2"
          aria-setsize="-1"
          data-aria-posinset="5"
          data-aria-level="1"
        >
          <span data-contrast="none">
            im Falle der Verarbeitung aufgrund einer Einwilligung oder zur
            Erfüllung eines Vertrags, die Sie betreffenden personenbezogenen
            Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
            gängigen und maschinenlesbaren Format zu erhalten, und diese Daten
            einem anderen Verantwortlichen ohne Behinderung durch uns zu
            übermitteln oder die Daten direkt an den anderen Verantwortlichen zu
            übermitteln, soweit dies technisch machbar ist 
          </span>
          <b>
            <span data-contrast="none">
              (Art. 20 DS-GVO: Recht auf Datenübertragbarkeit)
            </span>
          </b>
          <span data-contrast="none">,</span>
          <span data-ccp-props="{}"> </span>
        </li>
      </ul>
      <ul>
        <li
          data-leveltext=""
          data-font="Symbol"
          data-listid="2"
          aria-setsize="-1"
          data-aria-posinset="1"
          data-aria-level="1"
        >
          <span data-contrast="none">
            jederzeit Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des
            Orts des mutmaßlichen Verstoßes, einzulegen, wenn Sie der Ansicht
            sind, dass die Verarbeitung der sie betreffenden personenbezogenen
            Daten gegen geltendes Recht verstößt 
          </span>
          <b>
            <span data-contrast="none">
              (Art. 77 DSGVO i. V. m. § 19 BDSG: Recht auf Beschwerde bei einer
              Aufsichtsbehörde)
            </span>
          </b>
          <span data-contrast="none">.</span>
          <span data-ccp-props="{}"> </span>
        </li>
      </ul>
      <span data-contrast="none">
        Außerdem haben Sie aus Gründen, die sich aus Ihrer besonderen Situation
        ergeben das Recht, jederzeit gegen die Verarbeitung Sie betreffender
        personenbezogener Daten, die für die Wahrung einer Aufgabe im
        öffentlichen Interesse erforderlich ist, oder die in Ausübung
        öffentlicher Gewalt erfolgt, Widerspruch einzulegen (Art. 21 DS-GVO:
        Widerspruchsrecht).
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Wenn Sie uns eine Einwilligung erteilt haben, haben Sie schließlich das
        Recht, Ihre Einwilligung jederzeit zu widerrufen. Alle
        Datenverarbeitungen die wir bis zu Ihrem Widerruf vorgenommen haben,
        bleiben in diesem Fall rechtmäßig. Zu diesem Zweck können Sie einfach
        den in jeder Mail enthaltenen Link anklicken und sich von dem
        E-Mail-Dienst abmelden oder eine Nachricht an 
      </span>
      <a href="mailto:kontakt@pkv-vergleichen.de">
        <span data-contrast="none">kontakt@pkv-vergleichen.de</span>
      </a>
      <span data-contrast="none">
         schicken. Wenn Sie uns in dieser Nachricht mitteilen, künftig keine
        E-Mails erhalten zu wollen, werden wir an die von Ihnen angegebene
        E-Mail-Adresse keine Nachrichten mehr versenden. Hiervon unberührt sind
        E-Mails, die wir Ihnen zur Erfüllung eines etwaig mit Ihnen
        geschlossenen Vertrags übermitteln (zum Beispiel von Ihnen angefragte
        Vergleichsangebote).
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="7">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Ihre Pflicht zur Bereitstellung von personenbezogenen Daten
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Sie haben keine vertragliche, oder gesetzliche Pflicht uns
        personenbezogene Daten bereitzustellen. Allerdings sind wir ohne die von
        Ihnen mitgeteilten Daten nicht in der Lage, Ihnen unsere vertraglichen
        Leistungen und Services anzubieten.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="8">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Bestehen von automatisierten Entscheidungsfindungen
              (einschließlichProfiling)
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Wir verwenden automatisierte Entscheidungsfindungen, um Ihnen einen den
        passenden Service liefern zu können und einen zu Ihnen passenden Berater
        zu vermitteln. Inwiefern es danach zu einer rechtlichen Folge in Form
        des Vertragsschlusses kommt, unterliegt jedoch der freien Entscheidung
        zwischen Ihnen und dem Finanzberater.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="9">
        <li>
          <b>
            <span data-contrast="none"> Sicherheit</span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um
        zu gewährleisten, dass Ihre persönlichen Daten vor Verlust, unrichtigen
        Veränderungen oder unberechtigten Zugriffen Dritter geschützt sind. In
        jedem Fall haben von unserer Seite nur berechtigte Personen Zutritt zu
        Ihren persönlichen Daten, und dies auch nur insoweit, als es im Rahmen
        der oben genannten Zwecke erforderlich ist. Die Sicherheitsmaßnahmen
        werden ständig den verbesserten technischen Möglichkeiten angepasst.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Sie haben selbstverständlich die Möglichkeit, Ihr Einverständnis
        jederzeit zu widerrufen. Den Widerruf können Sie schriftlich an die
        Adresse AP Consulting GmbH Vermittlungsgesellschaft für
        Verbraucherverträge GmbH, Abteilung Datenschutz, Schlesische Straße
        29-30, 10997 Berlin (im Folgenden: AP Consulting GmbH) richten. Es
        genügt jedoch auch eine E-Mail an unseren Kundenservice unter der
        E-Mail-Adresse 
      </span>
      <a href="mailto:datenschutzbeauftragter@pkv-vergleichen.de">
        <span data-contrast="none">
          datenschutzbeauftragter@pkv-vergleichen.de
        </span>
      </a>
      <span data-contrast="none">
        . Für den Fall, dass Sie Ihr Einverständnis widerrufen, bitten wir um
        Verständnis dafür, dass wir bestimmte Dienste nicht oder nicht
        vollständig oder nicht störungsfrei erbringen können.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="10">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Welche internetspezifischen Datenverarbeitungen anfallen
            </span>
          </b>
        </li>
        <li>
          <b>
            <span data-contrast="none">a) Cookies</span>
          </b>
          <span data-contrast="none">
            Im Rahmen unserer Internetseiten verwenden wir so genannte Cookies.
            Hierbei handelt es sich um kleine Dateien, die auf Ihrer Festplatte
            gespeichert werden und durch welche uns bestimmte Informationen
            zufließen. Bei diesen Informationen handelt es sich insbesondere um
            den Login (Ihren Besuch), das Datum und die Zeit Ihres Besuches bei
            uns, die Cookie-Nummer sowie die URL der Website, von der aus Sie
            auf unsere Angebotsseiten gelangt sind. Die entsprechenden Daten
            werden dabei auf Servern unseres Dienstleisters gespeichert.
          </span>
        </li>
      </ol>
      <span data-contrast="none">
        Der Einsatz von Cookies ermöglicht es uns zunächst, Sie zu erkennen.
        Darüber hinaus können wir durch die Cookies unser Angebot optimal an
        Ihre individuellen Bedürfnisse anpassen. Außerdem verwenden wir Cookies,
        um die statistische Häufigkeit der Aufrufe der verschiedenen Seiten
        unseres Internet-Angebots sowie die allgemeine Navigation aufzuzeichnen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Die Verwendung von Cookies kann durch entsprechende Einstellungen in der
        von Ihnen benutzten Browser-Software unterbunden werden. Soweit die
        Cookies der Bereitstellung von konkreten Funktionen unserer
        Internetseiten dienen, erfolgt die Datenverarbeitung auf der Grundlage
        von Art. 6 Abs. 1 b) DS-GVO, im Übrigen auf Grundlage Ihrer Einwilligung
        nach Art. 6 Abs. 1 a) DS-GVO, die Sie jederzeit in den Cookie
        Einstellungen widerrufen können. Ergänzende Informationen hierzu,
        insbesondere zur Einordnung, Namen und Funktionsdauer der Cookies finden
        Sie ebenfalls in den Cookie Einstellungen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">b) Bing Ads</span>
          </b>
          <span data-contrast="none">
            Wirverwenden das Conversion Tracking “Bing Ads” der Microsoft
            Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA
            (“Microsoft”). 
          </span>
          <span data-contrast="none">
            Hierfür speichert Microsoft einen Cookie auf Ihrem Endgerät, wenn
            Sie über eine Microsoft Bing Anzeige zu uns gelangt sind. So können
            wir erkennen, dass jemand über eine Anzeige des Bing-Netzwerks zu
            einer bestimmten Zielseite gelangt ist. Es werden uns dabei nur die
            allgemeine Anzahl an Nutzern und keine persönlichen Informationen zu
            Ihrer Identität mitgeteilt.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Weitere Informationen zum Datenschutz und zu den eingesetzten Cookies
        bei Microsoft Bing finden Sie auf der Website von Microsoft: 
      </span>
      <a href="https://privacy.microsoft.com/de-de/privacystatement">
        <b>
          <span data-contrast="none">
            https://privacy.microsoft.com/de-de/privacystatement
          </span>
        </b>
      </a>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              c) Facebook Pixel und FacebookConversionAPI
            </span>
          </b>
          <span data-contrast="none">
            Wir verwenden das Facebook-Pixel der Facebook Ireland Limited, 4
            Grand Canal Square, Dublin 2, Irland auf unseren Seiten. Dieses
            bietet mehrere Funktionen an, von der wir die Funktion
            Custom Audiences  Hiermit kann Ihnen – wenn Sie ein Nutzerkonto bei
            Facebook haben – auf Facebook gezielt unsere Werbung angezeigt
            werden. Das Facebook Pixel stellt hierzu eine direkte Verbindung
            ihrer Seite mit den Facebook-Servern her. Dabei werden Ihre
            Nutzungsdaten an Facebook zu Analyse- und Marketingzwecken
            übermittelt. Haben Sie ein Facebook-Konto, so können diese Daten
            Ihnen zugeordnet werden. Durch das Tracking können Ihre Aktivitäten
            von Facebook so unter Umständen über mehrere Seiten verfolgt werden.
            Wir haben keinerlei Einfluss auf eventuelle Verarbeitungen von
            personenbezogenen Daten, da diese allein im Verantwortungsbereich
            von Facebook liegen. Nähere Informationen zur Erhebung und Nutzung
            der Daten durch Facebook finden Sie in den Datenschutzhinweisen von
            Facebook unter 
          </span>
          <a href="https://www.facebook.com/policy.php">
            <b>
              <span data-contrast="none">
                https://www.facebook.com/policy.php
              </span>
            </b>
          </a>
          <span data-contrast="none">
            . Wenn Sie der Nutzung von Facebook Website
            Custom Audiences widersprechen möchten, können Sie die
            entsprechenden Einstellungen in Ihrem Facebook Nutzerkonto vornehmen
            unter: 
          </span>
          <a href="https://www.facebook.com/ads/website_custom_audiences/">
            <b>
              <span data-contrast="none">
                https://www.facebook.com/ads/website_custom_audiences/
              </span>
            </b>
          </a>
          <span data-contrast="none">
            . Für die Ausrichtung der Werbung auf Facebook sind wir
            datenschutzrechtlich gemeinsam mit Facebook verantwortlich. Nähere
            Informationen finden Sie unter: 
          </span>
          <a href="https://www.facebook.com/legal/controller_addendum">
            <b>
              <span data-contrast="none">
                https://www.facebook.com/legal/controller_addendum
              </span>
            </b>
          </a>
          <span data-contrast="none"> und </span>
          <a href="https://www.facebook.com/legal/terms/businesstools_jointprocessing">
            <b>
              <span data-contrast="none">
                https://www.facebook.com/legal/terms/businesstools_jointprocessing
              </span>
            </b>
          </a>
          <span data-contrast="none">
             sowie in Facebooks Datenschutzerklärung 
          </span>
          <a href="https://de-de.facebook.com/policy.php">
            <b>
              <span data-contrast="none">
                https://de-de.facebook.com/policy.php
              </span>
            </b>
          </a>
          <span data-contrast="none">.</span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Zudem verwenden wir die Facebook Conversion API. Es werden hierbei auf
        Ihrem Gerät keine Cookies oder andere Dateien gespeichert oder auf Daten
        auf Ihrem Gerät zugegriffen. Mit der Conversion API ist es uns möglich,
        nachzuverfolgen, wie erfolgreich unsere Aktivitäten auf Facebook sind.
        Wenn Sie über eine Anzeige auf Facebook zu uns gelangen, ordnet Facebook
        Ihnen eine ID zu und teilt uns diese ID mit. Wenn Sie anschließend eines
        unserer Formulare absenden, übermitteln wir diese ID wiederum an
        Facebook, so dass Facebook ermitteln kann, von welcher Anzeige sie auf
        unsere Seite gelangt sind. Inhaltliche Daten des Formulars werden
        selbstverständlich nicht übertragen. Wir erhalten am Ende lediglich
        anonyme und aggregierte Statistiken zum Erfolg unserer Anzeigen aus
        denen wir keine einzelnen Nutzer identifizieren können. Facebook handelt
        im Rahmen dieser Aktivitäten in unserem Auftrag. Die Rechtsgrundlage für
        die Verarbeitung ist hierbei Art. 6 Abs. 1 lit. f) DSGVO. Unser
        berechtigtes Interesse liegt darin, den Erfolg unserer Kampagnen messen
        zu können.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Die mit Nutzung der Facebook Conversion API gewonnen Informationen
        werden ebenfalls verwendet, um zukünftige Kampagnen zu optimieren, was
        ebenfalls in unserem berechtigten Interesse liegt (Art. 6 Abs. 1 lit. f)
        DSGVO). Sie können auch hier die Verarbeitungen verhindern und
        widersprechen, indem Sie in Ihren Privatsphäre-Einstellungen auf
        Facebook die entsprechende Einstellung vornehmen. Für Verarbeitungen zu
        diesen Zwecken sind wir datenschutzrechtlich gemeinsam mit Facebook
        verantwortlich. Näheres zu unserer Kooperatioon und zu den jeweiligen
        Zuständigkeiten finden Sie unter: 
      </span>
      <a href="https://www.facebook.com/legal/controller_addendum">
        <b>
          <span data-contrast="none">
            https://www.facebook.com/legal/controller_addendum
          </span>
        </b>
      </a>
      <span data-contrast="none"> und </span>
      <a href="https://www.facebook.com/legal/terms/businesstools_jointprocessing">
        <b>
          <span data-contrast="none">
            https://www.facebook.com/legal/terms/businesstools_jointprocessing
          </span>
        </b>
      </a>
      <span data-contrast="none">
         sowie in Facebooks Datenschutzerklärung 
      </span>
      <a href="https://de-de.facebook.com/policy.php">
        <b>
          <span data-contrast="none">
            https://de-de.facebook.com/policy.php
          </span>
        </b>
      </a>
      <span data-contrast="none">.</span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Sie können die beschriebenen Verarbeitungen verhindern und
        widersprechen, indem Sie in Ihren Privatsphäre-Einstellungen auf
        Facebook unter 
      </span>
      <a href="https://www.facebook.com/settings?tab=privacy">
        <b>
          <span data-contrast="none">
            https://www.facebook.com/settings?tab=privacy
          </span>
        </b>
      </a>
      <span data-contrast="none">
         die entsprechende Einstellung vornehmen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">d) FacebookSocialPlugins</span>
          </b>
          <span data-contrast="none">
            Dieses Angebot verwendet Social Plugins („Plugins“) des sozialen
            Netzwerkes facebook.com, welches von der Facebook Inc., 1601 S.
            California Ave, Palo Alto, CA 94304, USA betrieben wird
            („Facebook“). Die Plugins sind an einem der Facebook-Logos erkennbar
            (weißes „f“ auf blauer Kachel oder ein „Daumen hoch“-Zeichen) oder
            sind mit dem Zusatz „Facebook Social Plugin“ gekennzeichnet. Die
            Liste und das Aussehen der Facebook Social Plugins kann hier
            eingesehen werden: 
          </span>
          <a href="https://developers.facebook.com/docs/plugins/">
            <b>
              <span data-contrast="none">
                https://developers.facebook.com/docs/plugins/
              </span>
            </b>
          </a>
          <span data-contrast="none">.</span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Wenn ein Nutzer eine Website dieses Angebots aufruft, die ein
        solches Plugin enthält, baut sein Browser eine direkte Verbindung mit
        den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook
        direkt an Ihren Browser übermittelt und von diesem in die Website
        eingebunden. Der Anbieter hat daher keinen Einfluss auf den Umfang der
        Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die
        Nutzer daher entsprechend seinem Kenntnisstand:
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Durch die Einbindung der Plugins erhält Facebook die Information, dass
        ein Nutzer die entsprechende Seite des Angebots aufgerufen hat. Ist der
        Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
        Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum
        Beispiel den Like Button betätigen oder einen Kommentar abgeben, wird
        die entsprechende Information von Ihrem Browser direkt an Facebook
        übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von
        Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook seine
        IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in
        Deutschland nur eine anonymisierte IP-Adresse gespeichert.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
        Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und
        Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer können
        diese den Datenschutzhinweisen von Facebook entnehmen: 
      </span>
      <a href="https://www.facebook.com/about/privacy/">
        <b>
          <span data-contrast="none">
            https://www.facebook.com/about/privacy/
          </span>
        </b>
      </a>
      <span data-contrast="none">.</span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook
        über dieses Angebot Daten über ihn sammelt und mit seinen bei Facebook
        gespeicherten Mitgliedsdaten verknüpft, muss er sich vor dem Besuch des
        Internetauftritts bei Facebook ausloggen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Ebenfalls ist es möglich Facebook-Social-Plugins mit Add-ons für Ihren
        Browser zu blocken, zum Beispiel mit dem „Facebook Blocker“.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">e) Google Analytics</span>
          </b>
          <span data-contrast="none">
            Diese Website und weitere Online-Angebote von AP Consulting GmbH
            benutzen den Webanalysedienst Google Analytics und den Dienst
            GoogleRemarketing, beides Angebote der Google LLC,
            1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“).
            Durch den Dienst Google Remarketing werden Nutzer, die unsere
            Internetseiten und Onlinedienste bereits besucht und sich für das
            Angebot interessiert haben, durch zielgerichtete Werbung auf den
            Seiten des Google Partner Netzwerks erneut angesprochen. Die
            Einblendung der Werbung erfolgt durch den Einsatz von Cookies. Mit
            Hilfe der Textdateien kann das Nutzerverhalten beim Besuch der
            Website analysiert und anschließend für gezielte Produktempfehlungen
            und interessenbasierte Werbung genutzt werden. Auch Google Analytics
            verwendet Cookies. Die durch den Cookie erzeugten Informationen über
            Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse)
            werden an einen Server von Google in den USA übertragen und dort
            gespeichert. Wir setzen aber Google Analytics selbstverständlich mit
            der Funktion anonymizeIP ein, wodurch die IP-Adresse durch
            Maskierung absolut anonymisiert wird.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Google wird die Informationen benutzen, um Ihre Nutzung der Website
        auszuwerten, um Reports über die Websiteaktivitäten für die
        Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung
        und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch
        wird Google diese Informationen gegebenenfalls an Dritte übertragen,
        sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten
        im Auftrag von Google verarbeiten. Drittanbieter, einschließlich Google,
        verwenden die in den Cookies gespeicherten Informationen im Rahmen des
        Dienstes Google Remarketing auch zum Schalten von Anzeigen auf anderen
        Websites auf Grundlage vorheriger Besuche eines Nutzers auf dieser
        Website. Google wird in keinem Fall Ihre ohnehin maskierte IP-Adresse
        mit anderen Daten von Google in Verbindung bringen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">f) Google Ads</span>
          </b>
          <span data-contrast="none">
            Unsere Webseite nutzt Ads, eine Technologie von Google.
          </span>
          <span data-contrast="none">
            Wir verwenden zum einen das Conversion-Tracking. Das bedeutet, dass
            wir mittels eines Cookies erkennen können, über welche Anzeige des
            AdWords-Netzwerks Sie zu uns gelangt sind und welches Verhalten so
            auf unserer Seite ausgelöst wurde. Für uns ergibt sich so ein
            besseres Bild über die Wirksamkeit unserer Anzeigen und wir nutzen
            die Daten für Zwecke der Statistik und Marktforschung. Diese Daten
            sind für uns anonym. Das bedeutet, wir können Sie nicht Ihrer Person
            zuordnen.
          </span>
          <span data-contrast="none">
            Unsere Website nutzt zum anderen die Remarketing-Funktion von Google
            Ads. Diese Funktion hilft uns dabei unseren Besuchern
            interessenbezogene Werbeanzeigen zu präsentieren. Ihr Browser
            speichert dazu Cookies auf Ihrem Endgerät, die es uns ermöglichen
            Sie wiederzuerkennen, wenn Sie Websites aufrufen, die dem
            Werbenetzwerk von Google angehören. So können Ihnen Werbeanzeigen
            präsentiert werden, die sich auf Inhalte beziehen, welche Sie sich
            zuvor auf anderen Websites angesehen haben. Wir erhalten hierdurch
            keine personenbezogenen Daten von Ihnen.
          </span>
          <span data-contrast="none">
            Sie können die Remarketing Funktion deaktivieren, indem Sie die
            entsprechenden Einstellungen unter
          </span>
          <a href="https://adssettings.google.com/">
            <b>
              <span data-contrast="none">https://adssettings.google.com</span>
            </b>
          </a>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
        <li>
          <b>
            <span data-contrast="none">g) Twitter</span>
          </b>
          <span data-contrast="none">
            Dieses Angebot nutzt die Schaltflächen des Dienstes Twitter. Diese
            Schaltflächen werden angeboten durch die Twitter Inc., 795Folsom ,
            Suite 600, San Francisco, CA 94107, USA. Sie sind an Begriffen wie
            „Twitter“ oder „Folge“, verbunden mit einem stilisierten blauen
            Vogel, erkennbar. Mit Hilfe der Schaltflächen ist es möglich, einen
            Beitrag oder eine Seite dieses Angebotes bei Twitter zu teilen oder
            dem Anbieter bei Twitter zu folgen.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Wenn ein Nutzer eine Website dieses Internetauftritts aufruft, die einen
        solchen Button enthält, baut sein Browser eine direkte Verbindung mit
        den Servern von Twitter auf. Der Inhalt der Twitter-Schaltflächen wird
        von Twitter direkt an den Browser des Nutzers übermittelt. Der Anbieter
        hat daher keinen Einfluss auf den Umfang der Daten, die Twitter mit
        Hilfe dieses Plugins erhebt und informiert die Nutzer entsprechend
        seinem Kenntnisstand. Nach diesem werden lediglich die IP-Adresse des
        Nutzers sowie die URL der jeweiligen Website beim Bezug des Buttons mit
        übermittelt, aber nicht für andere Zwecke als die Darstellung des
        Buttons genutzt.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Weitere Informationen hierzu finden sich in der Datenschutzerklärung von
        Twitter unter 
      </span>
      <a href="https://twitter.com/de/privacy">
        <b>
          <span data-contrast="none">https://twitter.com/de/privacy</span>
        </b>
      </a>
      <span data-contrast="none">.</span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">h)Yandex</span>
          </b>
          <span data-contrast="none">
            Wir verwenden Yandex, einen Webanalyse- und Trackingdienst der
            Firma Yandex  16, Lva Tolstogo St., Moscow 119021,
            Russland. Yandex verwendet Cookies, um Ihre Nutzung unserer Website
            zu analysieren und für uns Reports darüber zusammenzustellen. Dabei
            werden Ihre Daten, u.a. auch Ihre IP-Adresse, an einen Server in
            Russland übermittelt, wo Yandex pseudonyme Nutzungsprofile erstellt.
            Weitere Informationen finden Sie in der Datenschutzerklärung unter 
          </span>
          <a href="https://yandex.com/legal/confidential/?lang=en">
            <b>
              <span data-contrast="none">
                https://yandex.com/legal/confidential/?lang=en
              </span>
            </b>
          </a>
          <span data-contrast="none">.</span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
        <li>
          <b>
            <span data-contrast="none">i) Push-Benachrichtigungen</span>
          </b>
          <span data-contrast="none">
            Sie können sich zum Empfang von unseren Push-Benachrichtigungen
            anmelden. Zum Versand unserer Push-Benachrichtigungen nutzen wir den
            Versanddienst „CleverPush“, der von derCleverPush UG
            (haftungsbeschränkt), Tondernstr. 1, 22049 Hamburg („CleverPush“),
            betrieben wird. Sie erhalten über unsere Push-Benachrichtigungen
            regelmäßig Informationen über Versicherungen, Vorsorge und Finanzen.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Zur Anmeldung müssen Sie die Abfrage Ihres Browsers zum Erhalt von
        Benachrichtigungen bestätigen. Dieser Prozess wird
        von CleverPush dokumentiert und gespeichert. Hierzu gehört die
        Speicherung des Anmeldezeitpunkts sowie Ihre Browser-ID bzw. Ihre
        Geräte-ID. Die Erhebung dieser Daten ist erforderlich, damit wir im
        Falle eines Missbrauchs die Abläufe nachvollziehen können und dient
        deshalb unserer rechtlichen Absicherung.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Um Ihnen die Push-Benachrichtigungen anzeigen zu können, erhebt und
        verarbeitet CleverPush in unserem Auftrag Ihre Browser-ID sowie im Falle
        des mobilen Zugriffs Ihre Geräte-ID.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Indem Sie unsere Push-Benachrichtigungen abonnieren, erklären Sie sich
        mit deren Empfang einverstanden. Rechtsgrundlage für die Verarbeitung
        Ihrer Daten nach Anmeldung zu unseren Push-Benachrichtigungen ist bei
        Vorliegen Ihrer Einwilligung Art. 6 Abs. 1 lit. a DSGVO.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        CleverPush wertet unsere Push-Benachrichtigungen zudem statistisch
        aus. CleverPush kann so erkennen, ob und wann unsere
        Push-Benachrichtigungen angezeigt und von Ihnen angeklickt wurden.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Ihre Einwilligung in die Speicherung und Verwendung Ihrer
        personenbezogenen Daten zum Erhalt unserer Push-Benachrichtigungen und
        der zuvor beschriebenen statistischen Erhebung können Sie jederzeit mit
        Wirkung für die Zukunft widerrufen. Zum Zwecke des Widerrufs der
        Einwilligung können Sie die dazu vorgesehene Einstellung zum Erhalt von
        Push Benachrichtigungen in Ihrem Browser ändern. Sofern Sie unsere
        Push-Benachrichtigungen auf einem Desktop-PC mit dem Betriebssystem
        „Windows“ nutzen, können Sie die unsere Push-Benachrichtigungen auch
        über einen Rechtsklick auf die jeweilige Push-Benachrichtigung in den
        dort erscheinenden Einstellungen abbestellen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Ihre Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Ihre Daten werden demnach
        solange gespeichert, wie das Abonnement der unserer
        Push-Benachrichtigungen aktiv ist. Unter folgendem Link wird der
        Austragungsprozess detailliert erklärt: 
      </span>
      <a href="https://cleverpush.com/faq">
        <b>
          <span data-contrast="none">https://cleverpush.com/faq</span>
        </b>
      </a>
      <span data-contrast="none">.</span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">j) Trustpilot</span>
          </b>
          <span data-contrast="none">
            Wir nutzen das Bewertungsprotal Trustpilot, das
            vonTrustPilot A/S, Pilestraede 58, 5th floor, 1112 Kopenhagen,
            Dänemark betrieben wird. Um unseren Service ständig zu verbessern,
            bieten wirunseren Kunden die Möglichkeit, uns über dieses
            unabhängige Portal zu bewerten, ohne dass wir dies in irgendeiner
            Weise beeinflussen können. Es wird für jede Bestellung, die über
            unsere Internetseite erfolgt, eine Einladung generiert, eine
            Bewertung abzugeben. Zu diesem Zweck werden Ihr Name, Vorname,
            Emailadresse und eine Referenznummer (Bestellnummer für die
            eindeutige Zuordnung) an Trustpilot übermittelt. Diese Daten werden
            Trustpilot weder selbst genutzt noch an Dritte weitergegeben. Eine
            Bewertung unseres Unternehmens kann auf der Seite 
          </span>
          <a href="https://de.trustpilot.com/review/www.finanzen.de">
            <b>
              <span data-contrast="none">
                https://de.trustpilot.com/review/www.AP Consulting GmbH
              </span>
            </b>
          </a>
          <span data-contrast="none"> durchgeführt werden.</span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Die Verifizierung der Bewertung erfolgt dabei auf Bass der
        Referenznummer (Bestellnummer) durch einen eigens erzeugten Link. Die
        Abgabe einer Bewertung ist freiwillig. Für die Abgabe einer Bewertung
        bzw. die Erfassung eines Kundenfeedbacks ist die Anlage/Eröffnung eines
        Nutzerprofiles bei Trustpilot erforderlich. Neben einer Bewertung für
        das einladende Unternehmen können dann auch Bewertungen für jedes
        beliebige Unternehmen auf dem Bewertungsportal von Trustpilot erfasst
        werden. Wird eine Bewertungen durch Klick auf den in der Einladung
        enthaltenen Link abgegeben, wird nach Eingabe der persönlichen Daten
        (Name und Emailadresse zur Verifizierung), automatisch ein Nutzerprofil
        bei TrustPilot angelegt.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">
        Hiermit geht die Zustimmung zu den Datenschutzbestimmungen sowie
        allgemeinen Geschäftsbedingungen von Trustpilot einher. Diese sind
        einsehbar auf der Webpräsents von Trustpilot, unter: 
      </span>
      <a href="http://de.legal.trustpilot.com/end-user-privacy-terms">
        <b>
          <span data-contrast="none">
            http://de.legal.trustpilot.com/end-user-privacy-terms
          </span>
        </b>
      </a>
      <span data-contrast="none">
         Mit der Aufgabe einer Bestellung über unsere Internetseite willigen Sie
        nach Art. 6 Abs. 1 lit. a) DSGVO der vorbenannten Übermittlung der
        Referenzdaten an Trustpilot sowie mit dem automatisierten Versand einer
        Bewertungseinladung aus dieser Applikation ausdrücklich einverstanden.
        Das Bewertungsprofil für Moebel24 ist einsehbar unter: 
      </span>
      <a href="https://de.trustpilot.com/review/finanzen.de">
        <b>
          <span data-contrast="none">
            https://de.trustpilot.com/review/AP Consulting GmbH
          </span>
        </b>
      </a>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">k)Hotjar(hotjar )</span>
          </b>
          <span data-contrast="none">
            Diese Website nutzt Hotjar, um die Bedürfnisse unserer Nutzer besser
            zu verstehen und das Angebot auf dieser Webseite zu optimieren.
            Mithilfe der Technologie von Hotjar bekommen wir eine besseres
            Verständnis von den Erfahrungen unserer Nutzer (z.B. wieviel Zeit
            Nutzer auf welchen Seiten verbringen, welche Links sie anklicken,
            was sie mögen und was nicht etc.) und das hilft uns, unser Angebot
            am Feedback unserer Nutzer auszurichten. Hotjar arbeitet mit Cookies
            und anderen Technologien, um Informationen über das Verhalten
            unserer Nutzer und über ihre Endgeräte zu sammeln (insbesondere IP
            Adresse des Geräts (wird nur in anonymisierter Form erfasst und
            gespeichert), Bildschirmgröße, Gerätetyp (Unique
            Device Identifiers), Informationen über den verwendeten Browser,
            Standort (nur Land), zum Anzeigen unserer Webseite bevorzugte
            Sprache). Hotjar speichert diese Informationen in
            einem pseudonymisierten Nutzerprofil. Die Informationen werden weder
            von Hotjar noch von uns zur Identifizierung einzelner Nutzer
            verwendet oder mit weiteren Daten über einzelne Nutzer
            zusammengeführt. Weitere Informationen finden Sie
            in Hotjars Datenschutzerklärung hier.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Weitere Informationen über Hotjar Ltd. und über das Tool Hotjar finden
        Sie unter: 
      </span>
      <a href="https://www.hotjar.com/">
        <b>
          <span data-contrast="none">https://www.hotjar.com</span>
        </b>
      </a>
      <span data-contrast="none">
        Die Datenschutzerklärung der Hotjar Ltd. finden Sie unter: 
      </span>
      <a href="https://www.hotjar.com/privacy">
        <b>
          <span data-contrast="none">https://www.hotjar.com/privacy</span>
        </b>
      </a>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              l)Unbounce(Unbounce Marketing Solutions Inc.)
            </span>
          </b>
          <span data-contrast="none">
            Wir nutzen auf einzelnen Webseiten den Dienst von Unbounce Marketing
            Solutions Inc., 400-401 West Georgia Street, Vancouver, BC, V6B 5A1
            Kanada. Die betroffenen Seiten werden von Unbounce  Das bedeutet,
            dass der Browser des Users direkt mit Unbounce kommuniziert. Hierbei
            werden die IP-Adresse, die Browser-Signatur, das Betriebssystem, der
            Gerätetyp sowie das Datum und die Uhrzeit des Seitenbesuches
            an Unbounce übermittelt. Zusätzlich werden Cookies gesetzt. Die
            Daten werden uns nur anonymisiert zur Verfügung gestellt, sodass wir
            keinen Rückschluss auf einzelne Personen vornehmen können.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Weitere Informationen zu Unbounce finden Sie unter: 
      </span>
      <a href="https://unbounce.com/privacy/">
        <b>
          <span data-contrast="none">https://unbounce.com/privacy/</span>
        </b>
      </a>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">m)NiftyImages(NiftyImages LLC)</span>
          </b>
          <span data-contrast="none">
            Wir nutzen die Dienstleistung von NiftyImages LLC, 10640
            Mather Blvd Suite 110, Mather, CA 95655, USA in einigen Landingpages
            und Werbekampagnen. Hierfür werden die IP-Adresse, die
            Browser-Signatur, das Betriebssystem, der Gerätetyp sowie das Datum
            und die Uhrzeit, zu der die Nutzer die entsprechenden Seiten bzw.
            Kampagnen aufrufen an NiftyImages übermittelt. Die Daten werden nur
            zur Erstellung von maßgeschneiderten Bildern genutzt und nicht
            gespeichert.
          </span>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none">
        Weitere Informationen zum Datenschutz bei NiftyImages finden Sie unter: 
      </span>
      <a href="https://niftyimages.com/Terms">
        <b>
          <span data-contrast="none">https://niftyimages.com/Terms</span>
        </b>
      </a>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">n)CriteoA.</span>
          </b>
          <span data-contrast="none">
            Wir arbeiten mit Criteo SA- 32 rue blanche, 75009 Paris, France
            zusammen. In dem Zusammenhang werden Cookies auf unseren Webseiten
            zu Werbezwecken eingesetzt. So können wir Website-Besuchern, die
            sich für unsere Produkte interessieren, unsere Werbung auf
            Partner-Webseiten, in Apps und E-Mails zeigen.
            Re-targeting Technologien verwenden Cookies oder Werbe-IDs und
            zeigen ausgehend von Ihrem bisherigen Browsing-Verhalten Werbung an.
          </span>
          <span data-contrast="none">
            Wir können Daten, wie z.B. technische Kennungen, die aus Ihren
            Registrierungsinformationen auf unserer Website oder unserem
            CRM-System abgeleitet werden, mit unseren vertrauenswürdigen
            Werbepartnern teilen. Dadurch können Ihre Geräte und/oder Umgebungen
            verknüpft werden und ermöglicht Ihnen eine nahtlose
            Nutzungserfahrung mit den von Ihnen verwendeten Geräten.
          </span>
          <span data-contrast="none">
            Nähere Einzelheiten, sowie die Möglichkeit zur Ablehnung finden Sie
            hier: 
          </span>
          <a href="https://www.criteo.com/de/privacy/">
            <b>
              <span data-contrast="none">
                https://www.criteo.com/de/privacy/
              </span>
            </b>
          </a>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
        <li>
          <b>
            <span data-contrast="none">
              o) IPQualityScore.com (IPQualityScoreLLC)
            </span>
          </b>
          <span data-contrast="none">
            com wird zu Zwecken der Betrugsprävention auf unseren Websites
            genutzt. Hierfür kann Ihr Browser, Ihre IP-Adresse und die Details
            Ihres Betriebssystems verwendet werden, um verdächtiges Verhalten zu
            identifizieren. Diese Informationen werden anonym zum alleinigen
            Zweck der Betrugsprävention gesammelt und werden nicht an andere
            Anbieter verkauft oder weitergegeben.
          </span>
          <span data-contrast="none">Weiterführende Informationen unter </span>
          <a href="https://www.ipqualityscore.com/privacy-policy">
            <b>
              <span data-contrast="none">
                https://www.ipqualityscore.com/privacy-policy
              </span>
            </b>
          </a>
          <span data-ccp-props='{"134233118":true}'> </span>
        </li>
      </ol>
      <span data-contrast="none"> </span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-contrast="none">Stand: Januar 2021</span>
      <span data-ccp-props='{"134233118":true}'> </span>

      <span data-ccp-props="{}"> </span>
    </div>
  );
}
