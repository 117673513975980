import { connect } from "react-redux";
import {
  saveStep4,
  saveStep5,
  saveStep6,
  changeStep,
} from "../../../services/Actions/Auth";
function Step4(props) {
  function handleClick(e) {
    props.saveStep4(e.target.value);
    props.saveStep5("");
    props.saveStep6("");
    props.changeStep(7);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>How long have you been self-employed?</h2>
        </div>
        <div className="option-box">
          <button
            className="button outline"
            value="Start Up"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Start Up
          </button>
          <button
            className="button outline"
            value="Less than 3 years"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Less than 3 years
          </button>
          <button
            className="button outline"
            value="Longer than 3 years"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            Longer than 3 years
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveStep4,
  saveStep5,
  saveStep6,
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step4);
