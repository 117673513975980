import { connect } from "react-redux";
import "./SurveyHeader.scss";

function SurveyHeader(props) {
  let { step, totalStep } = props;
  let stepProgress = (step / totalStep) * 100;
  return (
    <div className="survey-header">
      <div className="container">
        <div className="row">
          <div className="brand-logo">
            <img
              className="img-responsive"
              src={require("../../assets/images/logo.png")}
              alt="Brand Logo"
            />
          </div>

          <div className="progress-container">
            <div className="external-link">
              <img
                className="img-responsive"
                src={require("../../assets/images/top-rated.png")}
                alt="Brand Logo"
              />
            </div>

            <div className="progressBar-wrapper">
              <div class="progress-bar">
                <div
                  class="progress-indicator"
                  style={{ width: `${stepProgress}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  let { step, totalStep } = state.Auth;
  return {
    step,
    totalStep,
  };
};

export default connect(mapStateToProps, null)(SurveyHeader);
