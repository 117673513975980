import { useState } from "react";
import { connect } from "react-redux";
import { saveStep12, changeStep } from "../../../services/Actions/Auth";
function Step12(props) {
  const [dob, setDob] = useState("");

  function handleClick() {
    if (dob) {
      props.saveStep12(dob);
      props.changeStep(13);
    } else {
      alert("Please Fill out the fields.");
    }
  }

  function handleChange(event) {
    let date = new Date(event.target.value);
    let monthNumber = date.getMonth() + 1;
    let month = getMonthFromNumber(monthNumber);

    let myDate = date.getUTCDate() + " " + month + " " + date.getUTCFullYear();

    setDob(myDate);
  }

  function getMonthFromNumber(month) {
    switch (month) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";

      default:
        return "Month";
    }
  }

  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>When were you born?</h2>
        </div>
        <div className="content-box">
          <div className="text" data-aos="fade-in" data-aos-delay="300">
            <p>
              <span>WHY?</span> The calculation of your monthly rate is, among
              other things, based on your age.
            </p>
          </div>
        </div>
        <div className="input-wrapper" data-aos="fade-up" data-aos-delay="500">
          <label htmlFor="dob">Date Of Birth</label>
          <input
            type="date"
            id="dob"
            placeholder="Date of Birth"
            name="dob"
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <button
          className="button"
          onClick={handleClick}
          data-aos="fade-up"
          data-aos-delay="700"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveStep12,
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step12);
