import {
  CHANGE_APP_BACKGROUND_IMAGE,
  CHANGE_STEP,
  SAVE_STEP_1,
  SAVE_STEP_2,
  SAVE_STEP_3,
  SAVE_STEP_4,
  SAVE_STEP_5,
  SAVE_STEP_6,
  SAVE_STEP_8,
  SAVE_STEP_9,
  SAVE_STEP_11,
  SAVE_STEP_12,
  SAVE_FINAL_STEP,
  SET_INITIAL_FORM_DATA,
} from "../Constants/Auth";

export const changeBackgroundImage = (data) => ({
  type: CHANGE_APP_BACKGROUND_IMAGE,
  payload: data,
});

export const changeStep = (data) => ({
  type: CHANGE_STEP,
  payload: data,
});

export const setInitialFormData = (data) => ({
  type: SET_INITIAL_FORM_DATA,
  payload: data,
});

export const saveStep1 = (data) => ({
  type: SAVE_STEP_1,
  payload: data,
});

export const saveStep2 = (data) => ({
  type: SAVE_STEP_2,
  payload: data,
});

export const saveStep3 = (data) => ({
  type: SAVE_STEP_3,
  payload: data,
});

export const saveStep4 = (data) => ({
  type: SAVE_STEP_4,
  payload: data,
});

export const saveStep5 = (data) => ({
  type: SAVE_STEP_5,
  payload: data,
});

export const saveStep6 = (data) => ({
  type: SAVE_STEP_6,
  payload: data,
});

export const saveStep8 = (data) => ({
  type: SAVE_STEP_8,
  payload: data,
});

export const saveStep9 = (data) => ({
  type: SAVE_STEP_9,
  payload: data,
});

export const saveStep11 = (data) => ({
  type: SAVE_STEP_11,
  payload: data,
});

export const saveStep12 = (data) => ({
  type: SAVE_STEP_12,
  payload: data,
});

export const saveFinalStep = (data) => ({
  type: SAVE_FINAL_STEP,
  payload: data,
});
