import { connect } from "react-redux";
import { changeStep } from "../../../services/Actions/Auth";
function Step7(props) {
  function handleClick(e) {
    props.changeStep(8);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>We are nearly done!</h2>
        </div>

        <div className="content-box" data-aos="fade-in" data-aos-delay="300">
          <div className="text">
            <p>We just need to know which services are important to you!</p>
          </div>

          <button
            className="button"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step7);
