import { connect } from "react-redux";
import { saveStep3, changeStep } from "../../../services/Actions/Auth";
function Step3(props) {
  function handleClick(e) {
    props.saveStep3(e.target.value);
    if (e.target.value === "I am employed") {
      props.changeStep(5);
    } else if (e.target.value === "I am self employed") {
      props.changeStep(4);
    } else if (e.target.value === "Other status") {
      props.changeStep(6);
    }
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>What is your profession?</h2>
        </div>
        <div className="option-box">
          <button
            className="button outline"
            value="I am employed"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            I am employed
          </button>
          <button
            className="button outline"
            value="I am self employed"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            I am self employed
          </button>
          <button
            className="button outline"
            value="Other status"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            Other status
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveStep3,
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step3);
