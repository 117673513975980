import { NavLink } from "react-router-dom";
import "./home.scss";

function Home() {
  return (
    <div className="home">
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="section-heading">
              <h1>We make german health insurance simple!</h1>
            </div>
            <div className="button-wrapper">
              <NavLink to="/survey" className="button">
                Start your free comparison!
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="what-we-offer">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="video-wrapper">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/P93FhzyYuz4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-6">
              <div className="content-box">
                <h3>
                  WHAT DO WE <span>OFFER?</span>
                </h3>
                <hr />
                <ul>
                  <li>Comparing the entire market</li>
                  <li>Lowest monthly rate guaranteed</li>
                  <li>Fulfilling every legal requirement for your visa</li>
                  <li>Helping you with bureaucracy and handling</li>
                  <li>English speaking customer service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
