import { Link } from "react-router-dom";
import "./header.scss";

function Header() {
  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <Link to="/" className="brand-logo">
            <img
              className="img-responsive"
              src={require("../../assets/images/logo.png")}
              alt="Brand Logo"
            />
          </Link>
        </div>

        <div className="external-link">
          <img
            className="img-responsive"
            src={require("../../assets/images/top-rated.png")}
            alt="Brand Logo"
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
