import {
  CHANGE_APP_BACKGROUND_IMAGE,
  CHANGE_STEP,
  SAVE_STEP_1,
  SAVE_STEP_2,
  SAVE_STEP_3,
  SAVE_STEP_4,
  SAVE_STEP_5,
  SAVE_STEP_6,
  SAVE_STEP_8,
  SAVE_STEP_9,
  SAVE_STEP_11,
  SAVE_STEP_12,
  SAVE_FINAL_STEP,
  SET_INITIAL_FORM_DATA,
} from "../Constants/Auth";
import { PURGE } from "redux-persist";

var initialState = {
  formData: {},
  step: 1,
  totalStep: 14,
  appBackgroundImage: "./assets/images/insurance-img1.jpg",
};

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case CHANGE_APP_BACKGROUND_IMAGE:
      return {
        ...state,
        appBackgroundImage: `./assets/images/${payload}`,
      };

    case CHANGE_STEP:
      return {
        ...state,
        step: payload,
      };

    case SET_INITIAL_FORM_DATA:
      return {
        ...state,
        formData: payload,
      };

    case SAVE_STEP_1:
      return {
        ...state,
        formData: {
          ...state.formData,
          insuredStatus: payload,
        },
      };

    case SAVE_STEP_2:
      return {
        ...state,
        formData: {
          ...state.formData,
          visaStatus: payload,
        },
      };

    case SAVE_STEP_3:
      return {
        ...state,
        formData: {
          ...state.formData,
          professionStatus: payload,
        },
      };

    case SAVE_STEP_4:
      return {
        ...state,
        formData: {
          ...state.formData,
          selfEmployedStatus: payload,
        },
      };

    case SAVE_STEP_5:
      return {
        ...state,
        formData: {
          ...state.formData,
          grossIncome: payload,
        },
      };

    case SAVE_STEP_6:
      return {
        ...state,
        formData: {
          ...state.formData,
          jobStatus: payload,
        },
      };

    case SAVE_STEP_8:
      return {
        ...state,
        formData: {
          ...state.formData,
          hospitalTreatment: payload,
        },
      };

    case SAVE_STEP_9:
      return {
        ...state,
        formData: {
          ...state.formData,
          dentalServices: payload,
        },
      };

    case SAVE_STEP_11:
      return {
        ...state,
        formData: {
          ...state.formData,
          region: payload.region,
          address: payload.address,
        },
      };

    case SAVE_STEP_12:
      return {
        ...state,
        formData: {
          ...state.formData,
          dateOfBirth: payload,
        },
      };

    case SAVE_FINAL_STEP:
      return {
        ...state,
        formData: {
          ...state.formData,
          firstName: payload.firstName,
          lastName: payload.lastName,
          emailAddress: payload.emailAddress,
          phoneNumber: payload.phoneNumber,
        },
      };

    default:
      return state;
  }
};

export default Auth;
