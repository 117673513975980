import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from 'react-router-dom'
import "./index.css";
import "./assets/styles/default-styles.scss";
import App from "./App";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { logger } from "redux-logger";
import rootReducer from "./services/Reducers";
// import rootSaga from "./store/sagas";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));

// sagaMiddleware.run(rootSaga);
ReactDOM.render(
  <Provider store={store}>
      <React.StrictMode>
        <HashRouter hashType="noslash">
        <App />
        </HashRouter>
      </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
