import "./styles.scss";
function Disclosure() {
  return (
    <div className="content-page">
      <b>
        <span data-contrast="none">Impressum</span>
      </b>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol>
        <li>
          <b>
            <span data-contrast="none">
              Verantwortlicher i. S. d. § 5 TMG für alle Inhalte, sofern im
              Folgenden kein abweichender Verantwortlicher angegeben ist:
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">MY-GERMAN-INSURANCE.DE</span>
      <span data-contrast="none">
        eine Marke der AP Insuro UG (Haftungsbeschränkt)
      </span>
      <span data-contrast="none">Berlepschstraße 35</span>
      <span data-contrast="none">36124 Eichenzell</span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">E-Mail: </span>
      <a href="mailto:kontakt@my-german-insurance.de">
        <span data-contrast="none">kontakt@my-german-insurance.de</span>
      </a>
      <span data-contrast="none"> </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">Telefon: (06659) 61 99 63</span>
      <span data-contrast="none">Telefax: (06659) 613 49 94</span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">Internet: </span>
      <a href="http://www.my-german-insurance.de/">
        <span data-contrast="none">www.my-german-insurance.de</span>
      </a>
      <span data-contrast="none"> </span>
      <span data-contrast="none"> </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">Geschäftsführerin: Patricia Adibi Nia</span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">
        Registereintrag: HRB 8078, Amtsgericht Fulda
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <a href="https://pkv-vergleichen.digital/datenschutzerklaerung/">
        <span data-contrast="none">Datenschutzhinweise</span>
      </a>
      <span data-contrast="none">
         My-GERMAN-INSURANCE.DE eine Marke der AP Insuro UG (Haftungsbeschränkt)
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      br
      <b>
        <span data-contrast="none">2. Haftungsausschluss</span>
      </b>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">
        AP Insuro UG (Haftungsbeschränkt) stellt auf dieser Website
        Informationen rund um Versicherungen dar. Die Seite ist ständigen
        Veränderungen und Ergänzungen unterworfen, welche der Aktualisierung und
        Bereicherung der angebotenen Informationen und Funktionen dienen. Die
        Informationen werden daher ohne Gewähr für Aktualität, Richtigkeit und
        Vollständigkeit zur Verfügung gestellt.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">
        AP Insuro UG (Haftungsbeschränkt) bzw. deren Verantwortliche haften
        nicht für direkte oder indirekte Schäden, einschließlich entgangener
        Gewinne, welche auf der Nutzung des Informations- und Funktionsangebotes
        dieser Seite beruhen.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
        für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">
        AP Insuro UG (Haftungsbeschränkt) ist unabhängig von Bundesbehörden,
        Versicherungen und anderen Organisationen. Die dargestellten Texte
        stellen, soweit nicht anders gekennzeichnet, ausschließlich die Meinung
        der Redaktion von AP Insuro UG (Haftungsbeschränkt) dar.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">
        Uns übersandte Daten werden entsprechend den relevanten Bestimmungen des
        Datenschutzes behandelt.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <b>
        <span data-contrast="none"> </span>
      </b>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="3">
        <li>
          <b>
            <span data-contrast="none"> Urheberrechtlich</span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Alle Rechte vorbehalten. Copyright für alle Inhalte bei AP Insuro UG
        (Haftungsbeschränkt). Nachdruck oder Vervielfältigung, auch
        auszugsweise, nur mit schriftlicher Genehmigung der AP Insuro UG
        (Haftungsbeschränkt) erlaubt. Die AP Insuro UG (Haftungsbeschränkt) ist
        nicht für die Inhalte der Anzeigen verantwortlich.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="4">
        <li>
          <b>
            <span data-contrast="none"> Bildnachweis</span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Nachweise über die auf unseren Seiten verwendeten Bilder finden Sie bei
        Adobe Stock.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <ol start="13">
        <li>
          <b>
            <span data-contrast="none">
              {" "}
              Datenschutz / Hinweise zum Tracking
            </span>
          </b>
        </li>
      </ol>
      <span data-contrast="none">
        Diese Website benutzt Google Analytics, einen Webanalysedienst der
        Google Inc. (“Google”) Google Analytics verwendet sog. “Cookies”,
        Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den
        Cookie erzeugten Informationen über Ihre Benutzung diese Website
        (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in den
        USA übertragen und dort gespeichert. Google wird diese Informationen
        benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um
        weitere mit der Websitenutzung und der Internetnutzung verbundene
        Dienstleistungen zu erbringen. Auch wird Google diese Informationen
        gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
        vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
        verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen
        Daten der Google in Verbindung bringen. Sie können die Installation der
        Cookies durch eine entsprechende Einstellung Ihrer Browser Software
        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
        gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich
        nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit
        der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor
        beschriebenen Art und Weise und zu dem zuvor benannten Zweck
        einverstanden.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-contrast="none">
        Der Betreiber hat Maßnahmen getroffen, die eine vollständige
        Übermittlung der IP-Adresse an Google unterbinden, so dass eine
        eindeutige Zuordnung zu einer Person über diese Methode nicht mehr
        möglich ist.
      </span>
      <span data-ccp-props='{"134233118":true}'> </span>
      <span data-ccp-props="{}"> </span>
      <span data-ccp-props="{}"> </span>
    </div>
  );
}

export default Disclosure;
