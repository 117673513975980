import { connect } from "react-redux";
import { changeStep } from "../../../services/Actions/Auth";
function Step10(props) {
  function handleClick(e) {
    props.changeStep(11);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>Done!</h2>
        </div>

        <div className="content-box">
          <div className="text" data-aos="fade-in" data-aos-delay="300">
            <p>
              The last thing we need to know is your current place of residence
              and your birthdate!
            </p>
          </div>

          <button
            className="button"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step10);
