import { connect } from "react-redux";
import { saveStep8, changeStep } from "../../../services/Actions/Auth";
function Step8(props) {
  function handleClick(e) {
    props.saveStep8(e.target.value);
    props.changeStep(9);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>How would you like to be treated in the hospital?</h2>
        </div>
        <div className="option-box">
          <button
            className="button outline"
            value="1-bed room with free choice of doctor"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            1-bed room <br /> with free choice of doctor
          </button>
          <button
            className="button outline"
            value="2-bed room with free choice of doctor"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            2-bed room <br /> with free choice of doctor
          </button>
          <button
            className="button outline"
            value="Multi-bed room with ward doctor"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            Multi-bed room <br /> with ward doctor
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveStep8,
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step8);
