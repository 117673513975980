import { connect } from "react-redux";
import { saveStep2, changeStep } from "../../../services/Actions/Auth";
function Step2(props) {
  function handleClick(e) {
    props.saveStep2(e.target.value);
    props.changeStep(3);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>What is your current visa status?</h2>
        </div>
        <div className="option-box">
          <button
            className="button outline"
            value="I don’t need a visa (EU-citizen)"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            I don’t need a visa (EU-citizen)
          </button>
          <button
            className="button outline"
            value="My visa is already unlimited"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            My visa is already unlimited
          </button>
          <button
            className="button outline"
            value="My visa is limited"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            My visa is limited
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveStep2,
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step2);
