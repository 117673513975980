import { useEffect } from "react";
import { connect } from "react-redux";
import { changeStep, changeBackgroundImage } from "../../services/Actions/Auth";
import Step1 from "../../components/Survey/Step1";
import Step2 from "../../components/Survey/Step2";
import Step3 from "../../components/Survey/Step3";
import Step4 from "../../components/Survey/Step4";
import Step5 from "../../components/Survey/Step5";
import Step6 from "../../components/Survey/Step6";
import Step7 from "../../components/Survey/Step7";
import Step8 from "../../components/Survey/Step8";
import Step9 from "../../components/Survey/Step9";
import Step10 from "../../components/Survey/Step10";
import Step11 from "../../components/Survey/Step11";
import Step12 from "../../components/Survey/Step12";
import Step13 from "../../components/Survey/Step13";
import FinalStep from "../../components/Survey/FinalStep";
import ThankYou from "../../components/Survey/ThankYou";
import SurveyHeader from "../SurveyHeader";
import "./survey.scss";

function Survey(props) {
  let { step } = props;

  function handleBack() {
    if (!(step === 5 || step === 6)) {
      props.changeStep(step - 1);
    } else {
      props.changeStep(3);
    }
  }

  useEffect(() => {
    if (step === 1) {
      props.changeBackgroundImage("insuredStatus.jpg");
    } else if (step === 2) {
      props.changeBackgroundImage("visaStatus.jpg");
    } else if (step === 3) {
      props.changeBackgroundImage("profession.jpg");
    } else if (step === 4) {
      props.changeBackgroundImage("selfEmployed.jpg");
    } else if (step === 5) {
      props.changeBackgroundImage("income.jpg");
    } else if (step === 6) {
      props.changeBackgroundImage("jobStatus.jpg");
    } else if (step === 7) {
      props.changeBackgroundImage("insurance.jpg");
    } else if (step === 8) {
      props.changeBackgroundImage("hospital.jpg");
    } else if (step === 9) {
      props.changeBackgroundImage("dental.jpg");
    } else if (step === 10) {
      props.changeBackgroundImage("insurance.jpg");
    } else if (step === 11) {
      props.changeBackgroundImage("region.jpg");
    } else if (step === 12) {
      props.changeBackgroundImage("birthday.jpg");
    } else if (step === 13) {
      props.changeBackgroundImage("insurance.jpg");
    } else if (step === 14) {
      props.changeBackgroundImage("insurance-img2.jpg");
    } else if (step === 15) {
      props.changeBackgroundImage("insurance.jpg");
    }
  }, [step]);

  return (
    <div className="survey">
      {step !== 15 && <SurveyHeader />}
      {step === 1 && <Step1 />}
      {step === 2 && <Step2 />}
      {step === 3 && <Step3 />}
      {step === 4 && <Step4 />}
      {step === 5 && <Step5 />}
      {step === 6 && <Step6 />}
      {step === 7 && <Step7 />}
      {step === 8 && <Step8 />}
      {step === 9 && <Step9 />}
      {step === 10 && <Step10 />}
      {step === 11 && <Step11 />}
      {step === 12 && <Step12 />}
      {step === 13 && <Step13 />}
      {step === 14 && <FinalStep />}
      {step === 15 && <ThankYou />}
      {step > 1 && step < 15 && (
        <button className="button back-btn" onClick={handleBack}>
          Back
        </button>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  let { step } = state.Auth;
  return {
    step,
  };
};

const mapDispatchToProps = {
  changeStep,
  changeBackgroundImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
