import { useState, useEffect } from "react";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { saveFinalStep, changeStep } from "../../../services/Actions/Auth";
import BeatLoader from "react-spinners/BeatLoader";
function FinalStep(props) {
  let { firstName, lastName, emailAddress, phoneNumber, formData } = props;
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  const [localFormData, setLocalFormData] = useState({
    firstName: firstName,
    lastName: lastName,
    emailAddress: emailAddress,
    phoneNumber: phoneNumber,
  });

  const sendEmail = (data) => {
    setLoading(true);
    // https://my-german-insurance-backend.herokuapp.com/mail
    Axios.post("https://my-german-insurance-backend.herokuapp.com/mail", data).then((response) => {
      if (response.data.msg === "success") {
        setLoading(false);
        alert("Email sent, awesome!");
      } else if (response.data.msg === "fail") {
        setLoading(false);
        alert("Oops, something went wrong. Try again");
      }
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (
      localFormData.firstName &&
      localFormData.lastName &&
      localFormData.emailAddress &&
      localFormData.phoneNumber
    ) {
      sendEmail(formData);
      // console.log("FORM DATA ::", formData);
    } else {
      alert("Please Fill out All the fields.");
    }
  };

  function handleChange(e) {
    localFormData[e.target.name] = e.target.value;
    props.saveFinalStep(localFormData);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>You will be surprised how much you can save.</h2>
        </div>
        <div className="content-box">
          <div className="text" data-aos="fade-in" data-aos-delay="300">
            <p>
              Where should we send the <span>tariff comparison</span>?
            </p>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="form-group">
            <div className="input-wrapper" data-aos="fade-up" data-aos-delay="500">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                placeholder="John"
                name="firstName"
                onChange={handleChange}
                value={localFormData.firstName}
                className="form-control"
              />
            </div>
            <div className="input-wrapper" data-aos="fade-up" data-aos-delay="700">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                placeholder="Doe"
                name="lastName"
                onChange={handleChange}
                value={localFormData.lastName}
                className="form-control"
              />
            </div>
          </div>
          <div className="input-wrapper" data-aos="fade-up" data-aos-delay="900">
            <label htmlFor="emailAddress">Email Address</label>
            <input
              type="text"
              id="emailAddress"
              placeholder="john@example.com"
              name="emailAddress"
              onChange={handleChange}
              value={localFormData.emailAddress}
              className="form-control"
            />
          </div>
          <div className="input-wrapper" data-aos="fade-up" data-aos-delay="1100">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              placeholder="Phone No for queries"
              name="phoneNumber"
              onChange={handleChange}
              value={localFormData.phoneNumber}
              className="form-control"
            />
          </div>
        </div>

        <button
          className="button"
          onClick={submitForm}
          data-aos="fade-up"
          data-aos-delay="1300"
        >
          {loading ? (
            <BeatLoader color={color} loading={loading} size={10} />
          ) : (
            "Request Now"
          )}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ Auth }) => {
  let { firstName, lastName, emailAddress, phoneNumber } = Auth.formData;
  let { formData } = Auth;
  return {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    formData,
  };
};

const mapDispatchToProps = {
  saveFinalStep,
  changeStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalStep);
