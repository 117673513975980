import { connect } from "react-redux";
import { saveStep9, changeStep } from "../../../services/Actions/Auth";
function Step9(props) {
  function handleClick(e) {
    props.saveStep9(e.target.value);
    props.changeStep(10);
  }
  return (
    <div className="Step">
      <div className="container">
        <div className="question-box" data-aos="fade-down" data-aos-delay="100">
          <h2>Are dental services important to you?</h2>
        </div>
        <div className="option-box">
          <button
            className="button outline"
            value="Are important to me"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Are important to me
          </button>
          <button
            className="button outline"
            value="I am not sure"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            I am not sure
          </button>
          <button
            className="button outline"
            value="Are not important to me"
            onClick={handleClick}
            data-aos="fade-up"
            data-aos-delay="700"
          >
            Are not important to me
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  saveStep9,
  changeStep,
};

export default connect(null, mapDispatchToProps)(Step9);
